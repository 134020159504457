<template>
  <div class="wrapper" :class="$i18n.locale" v-show="!isOnDemoLoading">
    <w-header class="gradual-top" :class="{animated: allReset, slideInDown: allReset}" slot="top" :title="headTitle"
      :logo="headLogo" :tip="`${$t('login.ip')}:${config.service_ip} ${$t('login.port')}:${config.service_port}`"
      :tools="tools" light :language-visible="config.language_visible">
    </w-header>
     <div class="loginBg" :alt="config.banners_en[0]">
      <img :src="config.banners_en[0]" alt="">
      <!-- <img :src="require('../../assets/login-background.png')" alt=""> -->
    </div>
    <w-login v-if="allReset" @login="login" :loading="btnLoading" slot="login" :demo-visible="!!config.demo_url"
      :demo-url="config.demo_url" :style="loginStyle" ref="login" :error-delay="8000" :title="$t('login.submitButton')"
      :forget-visible="config.forget_button_visible" :register-visible="config.register_button_visible"
      @sendcode="sendsms" :sms-login="config.sms_login_visible" :a-visible="agreed_visible">
    </w-login>
    <!-- <div class="middle" v-if="config.feature_visible">
      <h1 class="title">{{$t('login.ourService')}}</h1>
      <w-split gap="0" align="120px" vertical></w-split>
      <w-flex :flex-items="flexItems" ></w-flex>
    </div> -->
    <w-footer class="w-footer" v-if="allReset && true">
      <!-- <w-footer-line>  &nbsp; </w-footer-line> -->
      <w-footer-line v-if="false">
        <a :href="config.company_link">{{config.company_title}}</a>
        <w-split v-if="true" gap="8px" align="14px" color="#909399"></w-split>
        <span>{{config.company_address}}</span>
      </w-footer-line>
      <w-footer-line>
        <template v-if="config.VersionCode">
          <span>{{config.VersionCode}}</span>
          <w-split gap="8px" align="14px" color="#909399"></w-split>
        </template>
        <template v-if="config.Copyright">
          <span>{{config.Copyright}}</span>
          <w-split gap="8px" align="14px" color="#909399"></w-split>
        </template>
        <a :href="config.gswjUrl" target="_blank">
          <img :src="config.gswjImgSrc" class="gswj" />
        </a>
        <a href="https://beian.miit.gov.cn" target="_blank" v-if="config.icp">
          {{config.icp}}
        </a>
      </w-footer-line>
    </w-footer>
   

    <!-- <div class="video" v-if="videoURL">
      <video autoplay loop muted>
        <source :src="videoURL" type="video/mp4" />
      </video>
      <div class="msk"></div>
    </div> -->
    <!-- <div class="animate">
      <div class="ele an1"><img :src="require('../../assets/images/an1.png')" alt="">
        <div class="circle">
          <div class="img"></div>
        </div>
      </div>
      <div class="ele an2"><img :src="require('../../assets/images/an2.png')" alt="">
        <div class="circle">
          <div class="img"></div>
        </div>
      </div>
      <div class="ele an3"><img :src="require('../../assets/images/an3.png')" alt="">
        <div class="circle">
          <div class="img"></div>
        </div>
      </div>
      <div class="ele an4"><img :src="require('../../assets/images/an4.png')" alt="">
        <div class="circle">
          <div class="img"></div>
        </div>
      </div>
      <div class="ele an5"><img :src="require('../../assets/images/an5.png')" alt="">
        <div class="circle">
          <div class="img"></div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
  // import './config/components'
  import {
    setToken,
    removeToken
  } from '@/common/auth'
  import {
    setAutoLogin,
    getAutoLogin,
    setUserRole,
    setLastLoginInfo,
    getLastLoginInfo,
    setUserTreeSetting,
    getUserTreeSetting,
    setDefaultMap,
    setLanguage,
    getDefaultMap,
    getLanguage
  } from '@/common/cache'
  import {
    mapMutations
  } from 'vuex'
  import {
    BASE_URL,
    DOWNLOAD_URL,
    setCommonParam
  } from '@/api/config'

  const WLogin = require('./config/w-login.en.vue').default
  import WFlex from './config/w-flex'
  import WFooterLine from './config/w-footer-line'
  import WFooter from './config/w-footer'
  import WGradual from './config/w-gradual'
  import WHeader from './config/w-header'
  import WSplit from './config/w-split'
  import WToolItem from './config/w-tool-item'
  import {
    parseQueryString
  } from '@/common/utils'
  import {
    Login,
    LoginDemo,
    LoginSendVerifyCode,
    LoginByMobile
  } from '@/api/login'
  import {
    Config
  } from '@/api/common'
  import {
    setOutUrl,
    getVisitedViewInfo,
    setLanguageVisible,
    S_IMAGE_PREFIX_URL
  } from '@/common/cache'
  import Vue from 'vue'

  export default {
    components: {
      WFlex,
      WFooterLine,
      WFooter,
      WGradual,
      WHeader,
      WLogin,
      WSplit,
      WToolItem
    },
    data() {
      return {
        allReset: false,
        gradualStyle: {
          height: 'auto'
        },
        loginStyle: {
          right: '12.5%',
          top: '24%'
        },
        btnLoading: false,
        config: {
          logo: 'empty_logo.png',
          title: 'GPS监控平台',
          wechat_image: '',
          ios_image: '',
          android_image: '',
          forget_button_visible: true,
          register_button_visible: true,
          feature_visible: true,
          language_visible: false,
          banners: [],
          banners_en: [],
          company_title: '',
          company_link: '',
          company_phone: '',
          company_address: '',
          default_language: "zh",
          default_map: "Amap",
          icp: '粤ICP备09213828号-3',
          cert_id: 'ca5f63b3-0682-4acd-93a1-22920b2dac33',
          gswjUrl: '',
          gswjImgSrc: '',
          demo_url: '',
          service_ip: '119.23.233.52',
          service_port: 6000,
          sms_login_visible: false
        },
        headLogo: '',
        headTitle: '',
        isOnDemoLoading: false,
        build: '20210129',
        agreed_visible: true,
        videoURL: ''
      }
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.resize_1)
      window.removeEventListener('resize', this.resize_2)
    },
    async created() {
      // 初始化配置信息
      this.headLogo = require(`../../assets/logo/${this.config.logo}`)
      document.getElementById('ico').href = this.headLogo
      this.headTitle = '戈赛赛况平台'
      document.title = '戈赛赛况平台'
      // 滚动图
      // this.pics = [require('../../assets/banners/banner1.jpg'),
      //   require('../../assets/banners/banner2.jpg'),
      //   require('../../assets/banners/banner3.jpg'),
      //   require('../../assets/banners/banner4.jpg'),
      //   require('../../assets/banners/banner5.jpg')]

      // this.config.banners = [ 'banner1.jpg', 'banner2.jpg', 'banner3.jpg', 'banner4.jpg', 'banner5.jpg'].map(k => require(`../../assets/banners/zh/${k}`))
      // this.config.banners_en =  [ 'banner1.jpg', 'banner2.jpg', 'banner5.jpg' ].map(k => require(`../../assets/banners/en/${k}`))

      // this.config.banners = ['banner1.jpg'].map(k => require(`../../assets/banners/zh/${k}`))
      // this.config.banners_en = ['banner1.jpg'].map(k => require(`../../assets/banners/en/${k}`))

      //const correctPixel = 4
      const minRight = 40
      let minWidth = window.innerWidth < 1250 ? 1250 : window.innerWidth
      this.gradualStyle.height = (minWidth) / 1920 * 702 + 'px';
      //console.log('初始高度', this.gradualStyle.height)
      this.resize_1 = () => {
        let minWidth = window.innerWidth < 1250 ? 1250 : window.innerWidth
        this.gradualStyle.height = (minWidth) / 1920 * 702 + 'px';
        //console.log('计算高度', this.gradualStyle.height)
      }
      window.addEventListener('resize', this.resize_1);

      const right = (minWidth - 1250) / 2;
      this.loginStyle.right = (right < minRight ? minRight : right) + 'px';
      this.loginStyle.top = (parseInt(this.gradualStyle.height, 10) - 370) / 2 + 30 + 'px';
      //console.log('初始右边距,上边距', this.gradualStyle.right, this.loginStyle.top)
      this.resize_2 = () => {
        let minWidth = window.innerWidth < 1250 ? 1250 : window.innerWidth
        const right = (minWidth - 1250) / 2;
        this.loginStyle.right = (right < minRight ? minRight : right) + 'px';
        this.loginStyle.top = (parseInt(this.gradualStyle.height, 10) - 370) / 2 + 30 + 'px';
        //console.log('计算右边距,上边距', this.gradualStyle.right, this.loginStyle.top)
      }
      window.addEventListener('resize', this.resize_2);


      // 取后台首页配置信息
      try {
        const d = await this.initConfig()
        // console.log(d)
        // Logo路径
        if (this.isAbsolutePath(d.logo)) {
          this.headLogo = d.logo
        } else {
          this.headLogo = `${DOWNLOAD_URL}static/logo/${d.logo}`
        }
        document.getElementById('ico').href = this.headLogo

        // title
        this.headTitle = '戈赛赛况平台'
        document.title = '戈赛赛况平台'

        // Ip端口
        this.config.service_ip = d.service_ip
        this.config.service_port = d.service_port

        // 二维码
        this.config.wechat_image = d.wechat_image
        this.config.android_image = d.android_image
        this.config.ios_image = d.ios_image

        // CompanyInfo
        this.config.company_title = d.company_title
        this.config.company_link = d.company_link
        this.config.company_phone = d.company_phone
        this.config.company_address = d.company_address

        // 按钮是否可见
        this.config.forget_button_visible = d.forget_button_visible
        this.config.register_button_visible = d.register_button_visible
        this.config.feature_visible = d.feature_visible
        this.config.language_visible =  false 
        this.config.sms_login_visible = d.sms_login_visible

        // demo
        this.config.demo_url = ''

        // icp
        this.config.icp = d.icp
        this.config.Copyright = d.Copyright
        this.config.VersionCode = d.VersionCode
        this.config.cert_id = d.cert_id

        // 其他
        this.config.default_language = d.default_language
        let url = window.location.href
        if (url.search('net') > 0) {
          this.agreed_visible = false
        } else if (url.search('119.23.233.52') > 0) {
          // this.agreed_visible = false
          this.agreed_visible = true
        } else if (d.default_language == 'en') {
          this.agreed_visible = false
        } else {
          this.agreed_visible = true
        }
        this.config.default_map = d.default_map
        Vue.prototype['$IMAGE_URL_PREFIX'] = d.image_url_prefix ? d.image_url_prefix : Vue.prototype[
          '$IMAGE_URL_PREFIX']
        Vue.prototype['$IMAGE_URL_PREFIX_M'] = d.image_url_prefix ? d.image_url_prefix.replace('upload/image/',
          'api/posts/image.ashx') : Vue.prototype['$IMAGE_URL_PREFIX_M']

        if (d.image_url_prefix) {
          localStorage.setItem(S_IMAGE_PREFIX_URL, d.image_url_prefix)
        } else {
          localStorage.removeItem(S_IMAGE_PREFIX_URL)
        }

        if (d.banners && d.banners.length > 0) {
          this.config.banners_en = d.banners_en.map(k => `./static/banners/en/${k}`)

          // this.config.banners = d.banners.map(k => this.isAbsolutePath(k) ? k :
          //   `${DOWNLOAD_URL}static/banners/zh/${k}`)
          // this.config.banners_en = d.banners_en ? d.banners_en.map(k => this.isAbsolutePath(k) ? k :
          //   `${DOWNLOAD_URL}static/banners/en/${k}`) : this.config.banners
        }

        const defaultMap = getDefaultMap()
        const defaultLanguage = getLanguage()
        if (defaultMap !== d.default_map) {
          setDefaultMap(d.default_map)
          this.setCurrentMap(d.default_map)
          setCommonParam({
            current_map: d.default_map
          })
        } else {
          this.setCurrentMap(defaultMap)
          setCommonParam({
            current_map: defaultMap
          })
        }
        if (defaultLanguage !== d.default_language) {
          this.$i18n.locale = d.default_language
          setLanguage(d.default_language)
          setCommonParam({
            language: d.default_language
          })
        }

        setLanguageVisible(false)

      } catch (err) {
        console.error(err)
      }

      const lastLoginInfo = getLastLoginInfo()
      if (lastLoginInfo.HoldID) {
        // 存在登录历史, 修改图标和标题
        const initTitle = lastLoginInfo.PlatformTitle || this.$t('system')
        const initLogo = lastLoginInfo.PlatformLogo || `${BASE_URL}/assets/Logo?holdid=1`
        document.title = '戈赛赛况平台';
        document.getElementById('ico').href = initLogo
        this.headLogo = initLogo
        this.headTitle = '戈赛赛况平台'
      } else {
        lastLoginInfo.PlatformTitle = "戈赛赛况平台"
        lastLoginInfo.HoldID = undefined
        lastLoginInfo.PlatformLogo = this.headLogo
        setLastLoginInfo(lastLoginInfo)
      }

      // Demo默认登录信息
      this.isDemoThenLogin()

      // 工商网监
      //this.initGSWJ();

      if (window.compileVersion && window.compileVersion.length == 14) {
        this.build = window.compileVersion.substr(0, 8)
      }
    },
    mounted() {
      let ua = navigator.userAgent.toLowerCase();
      //       let isAndroid = ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1;  
      // 　　   //Ios终端
      //       let isiOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); 

      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        this.videoURL = ''
        //Ios
      } else if (/(Android)/i.test(navigator.userAgent)) {
        //Android终端
        this.videoURL = ''
      } else {
        this.videoURL = 'http://www.365qczx.com/video/V3.mp4'
      }

      this.allReset = true;

      // 自动登录
      setTimeout(() => {
        const localForm = getAutoLogin()
        if (localForm.isAuto) {
          this.login(Object.assign({}, {
            username: localForm.u,
            password: localForm.p,
            remember: localForm.isAuto,
            isAgree: localForm.agreed
          }))
        }
      }, 50)
    },
    methods: {
      sendsms(mobile, fn) {
        // 发送短信验证码
        LoginSendVerifyCode(mobile).then(ret => {
          if (ret.data.errCode) {
            this.$refs.login.error = ret.data.errMsg
          } else {
            fn()
          }
        }).catch(err => {
          if (!err.response) {
            this.$refs.login.error = this.$t('common.netError')
          } else if (err.response.status === 500) {
            this.$refs.login.error = this.$t('common.serverError')
          } else {
            this.$refs.login.error = err.message
          }
        })
      },
      // 登录
      login(form) {
        this.$refs.login.error = ''
        setAutoLogin(Object.assign({}, {
          u: form.username,
          p: form.password,
          isAuto: form.remember,
          isAgree: form.agreed
        }))

        if (form.ismobile) {
          if (!form.mobile) {
            this.$nextTick(() => {
              this.$refs.login.error = this.$t('login.pleaseInputMobile')
            })
            return
          }
          if (!form.code) {
            this.$nextTick(() => {
              this.$refs.login.error = this.$t('login.pleaseInputCode')
            })
            return
          }
          if (!form.agreed) {
            this.$alert(this.$t('login.loginCheck'))
            this.$refs.login.error = this.$t('login.loginCheck')
            return
          }
        } else {
          if (!form.username) {
            this.$nextTick(() => {
              this.$refs.login.error = this.$t('login.noUsernameTip')
            })
            return
          }
          if (!form.password) {
            this.$nextTick(() => {
              this.$refs.login.error = this.$t('login.passwordErrorTip')
            })
            return
          }
          if (!form.agreed) {
            this.$alert(this.$t('login.loginCheck'))
            this.$refs.login.error = this.$t('login.loginCheck')
            return
          }
        }

        this.btnLoading = true;
        //console.log(form);

        let fn = form.ismobile ?
          () => LoginByMobile(form.mobile, form.code) :
          () => Login(form.username, form.password)

        fn().then(async ret => {

          // console.log(ret)

          if (!ret.data.errCode || ret.data.errCode === 10) {
            if (ret.data.errCode === 10) {
              // 该账号有效期还有{0}天
              await this.$alert(ret.data.errMsg);
            } else if (ret.data.errCode === 11) {
              // 用户已过期
              await this.$alert(ret.data.errMsg)
              return
            }

            let state = getUserTreeSetting({})
            state = Object.assign(state, {
              checkList: [],
              containChild: true
            })

            // 清空勾选列表
            setUserTreeSetting(state)

            // 显示账号有效期
            setToken(ret.data.loginKey)

            // 增加图片地址
            ret.data.info.PlatformLogo = `${BASE_URL}/assets/Logo?holdid=${ret.data.info.HoldID}`
            this.setUserName(ret.data.info.LoginName) // 登录用户名
            this.setUserId(ret.data.info.UserID) // 登录用户ID
            this.setLoginInfo(ret.data.info) // 保存登录全部信息
            this.setCurrentHoldName(ret.data.info.HoldName) // 保存当前选择用户名
            this.setCurrentHoldId(ret.data.info.HoldID) // 保存当前选择用户ID

            // 保存最后登录信息(标题，图标)
            const lastLoginInfo = getLastLoginInfo()
            lastLoginInfo.PlatformTitle = ret.data.info.PlatformTitle
            lastLoginInfo.HoldID = ret.data.info.HoldID
            lastLoginInfo.PlatformLogo = ret.data.info.PlatformLogo
            setLastLoginInfo(lastLoginInfo)

            setUserRole({
              UserType: ret.data.info.UserType,
              UserFunList: ret.data.info.allFuns
            })

            // 加载缓存的标签页
            const views = getVisitedViewInfo()
            if (views && views.userId === ret.data.info.UserID && views.v === window.compileVersion) {
              this.setAllViews(views)
            } else {
              this.setAllViews({
                visitedViews: [],
                cachedViews: []
              })
            }

            // console.log(ret.data)
            if (ret.data.info.UserType === 961 || ret.data.info.UserType === 962 || ret.data.info.UserType ===
              963) {
              this.setMonitorLeft(false)
              // 安装人员
              this.$router.push('/wono/manage')
            } else {
              this.$router.push('/online/monitor')
            }
          } else {
            removeToken()
            setUserRole()
            this.$refs.login.error = ret.data.errMsg
          }
          this.btnLoading = false

        }).catch(err => {
          if (!err.response) {
            this.$refs.login.error = this.$t('common.netError')
          } else if (err.response.status === 500) {
            this.$refs.login.error = this.$t('common.serverError')
          } else {
            this.$refs.login.error = err.message
          }

          this.btnLoading = false
        })

        this.timer = setTimeout(() => {
          this.btnLoading = false;
        }, 5000);
      },
      isDemoThenLogin() {

        // 清空返回网址
        setOutUrl(null)

        // 获取window.location.search串
        var searchString = ''
        var startIndex = window.location.href.indexOf('?')
        if (startIndex != -1) {
          var endIndex = window.location.href.indexOf('#')
          if (endIndex < startIndex) {
            searchString = window.location.href.substring(startIndex)
          } else {
            searchString = window.location.href.substring(startIndex, endIndex)
          }
        }

        if (searchString) {
          var qs = parseQueryString(searchString)
          console.log('querystring', qs)
          if (qs['action'] === 'demo' && qs['refer']) {
            const refer = decodeURIComponent(qs['refer'])
            // 使用demo登录
            this.btnLoading = true;
            //console.log(form);
            window.history.replaceState(null, document.title, window.location.href.replace(searchString, ''))
            this.isOnDemoLoading = true
            LoginDemo(refer).then(async ret => {

              // console.log(ret)
              if (!ret.data.errCode || ret.data.errCode === 10) {
                setOutUrl(refer)
                if (ret.data.errCode === 10) {
                  // 该账号有效期还有{0}天
                  await this.$alert(ret.data.errMsg);
                } else if (ret.data.errCode === 11) {
                  // 用户已过期
                  await this.$alert(ret.data.errMsg)
                  return
                }

                let state = getUserTreeSetting({})
                state = Object.assign(state, {
                  checkList: [],
                  containChild: true
                })

                // 清空勾选列表
                setUserTreeSetting(state)

                // 显示账号有效期
                setToken(ret.data.loginKey)

                // 增加图片地址
                ret.data.info.PlatformLogo = `${BASE_URL}/assets/Logo?holdid=${ret.data.info.HoldID}`
                this.setUserName(ret.data.info.LoginName) // 登录用户名
                this.setUserId(ret.data.info.UserID) // 登录用户ID
                this.setLoginInfo(ret.data.info) // 保存登录全部信息
                this.setCurrentHoldName(ret.data.info.HoldName) // 保存当前选择用户名
                this.setCurrentHoldId(ret.data.info.HoldID) // 保存当前选择用户ID

                // 保存最后登录信息(标题，图标)
                const lastLoginInfo = getLastLoginInfo()
                lastLoginInfo.PlatformTitle = ret.data.info.PlatformTitle
                lastLoginInfo.HoldID = ret.data.info.HoldID
                lastLoginInfo.PlatformLogo = ret.data.info.PlatformLogo
                setLastLoginInfo(lastLoginInfo)

                setUserRole({
                  UserType: ret.data.info.UserType,
                  UserFunList: ret.data.info.allFuns
                })

                console.log(ret.data)
                if (ret.data.info.UserType === 961 || ret.data.info.UserType === 962 || ret.data.info.UserType ===
                  963) {
                  // 安装人员
                  this.$router.push('/wono/manage')
                } else {
                  this.$router.push('/online/monitor')
                }
              } else {
                removeToken()
                setUserRole()
                this.$refs.login.error = ret.data.errMsg
                console.error(ret.data)
                this.isOnDemoLoading = false

              }
              this.btnLoading = false

            }).catch(err => {
              this.$refs.login.error = err.message
              this.btnLoading = false
              this.isOnDemoLoading = false
            })

            this.timer = setTimeout(() => {
              this.btnLoading = false;
              this.isOnDemoLoading = false
            }, 5000);
          }
        }
      },
      initConfig() {
        let promise = new Promise((resolve, reject) => {
          Config()
            .then(ret => {
              if (ret.data.errCode) {
                return reject(ret.data)
              }
              return resolve(ret.data.config)
            })
            .catch(err => {
              return reject(err)
            })
        })
        return promise

        // let promise = new Promise((resolve, reject) => {
        //   const defaultMap = getDefaultMap()
        //   const defaultLanguage = getLanguage()

        //   if (defaultMap && defaultLanguage) {
        //     return resolve()
        //   } else {
        //     Config().then(ret => {
        //       if (!defaultMap) {
        //         setDefaultMap(ret.data.defaultMap)
        //       }
        //       if (!defaultLanguage) {
        //         this.$i18n.locale = ret.data.defaultLanguage
        //         setLanguage(ret.data.defaultLanguage)
        //         setCommonParam({language: ret.data.defaultLanguage})
        //       }

        //       resolve()
        //     }).catch(() => {
        //       reject()
        //     })
        //   }
        // })
        // return promise
      },
      setCookie(name, value) {
        //cookies设置JS
        var argv = arguments;
        var argc = arguments.length;
        var expires = (argc > 2) ? argv[2] : null;
        if (expires != null) {
          var LargeExpDate = new Date();
          LargeExpDate.setTime(LargeExpDate.getTime() + (expires * 1000 * 3600 * 24));
        }
        document.cookie = name + "=" + value + ((expires == null) ? "" : ("; expires=" + LargeExpDate.toGMTString())) +
          "; path=/";
      },
      initGSWJ() {
        var webprefix = "http://szcert.ebs.org.cn/"
        var iconImageURL = "http://szcert.ebs.org.cn/Images/govIcon.gif";
        // var niconImageURL = "http://szcert.ebs.org.cn/Images/newGovIcon.gif";
        // 链接地址
        this.config.gswjUrl = webprefix + this.config.cert_id;
        // 图片SRC
        this.config.gswjImgSrc = iconImageURL;
        // 在页面加载完成后，获取信息并且异步post到cert.gov.com

      },
      isAbsolutePath(path) {
        path = path || ''
        return path.toLowerCase().indexOf('http') === 0
      },
      ...mapMutations({
        setUserName: 'SET_USER_NAME',
        setUserId: 'SET_USER_ID',
        setLoginInfo: 'SET_LOGIN_INFO',
        setCurrentHoldId: 'SET_CURRENT_HOLD_ID',
        setCurrentHoldName: 'SET_CURRENT_HOLD_NAME',
        setAllViews: 'SET_ALL_VIEWS',
        setCurrentMap: 'SET_CURRENT_MAP',
        setMonitorLeft: 'SET_MONITOR_LEFT'
      })
    },
    computed: {
      flexItems() {
        const allService = this.$t('login.allService')
        if (allService instanceof Array) {
          return allService.map((k, index) => {
            return Object.assign(this.flexItemsDefault[index], k)
          })
        } else {
          return this.flexItemsDefault
        }
      },
      tools() {
        const arr = []
        // if (this.config.wechat_image) {
        //   let path = this.config.wechat_image
        //   if (!this.isAbsolutePath(path)) {
        //     path = `${DOWNLOAD_URL}static/image/${path}`
        //   }
        //   arr.push({
        //     name: this.$t('login.wechat'),
        //     icon: 'icon-weixin',
        //     remark: this.$t('login.followUS'),
        //     img: path,
        //     link: ''
        //   })
        // }
        // if (this.config.ios_image) {
        //   let path = this.config.ios_image
        //   if (!this.isAbsolutePath(path)) {
        //     path = `${DOWNLOAD_URL}static/image/${path}`
        //   }
        //   arr.push({
        //     name: 'IOS',
        //     icon: 'iconiOS',
        //     remark: this.$t('login.downloadIOSAPP'),
        //     img: path,
        //     link: 'http://dl.vodofo.com/APKUpdate/Index.html'
        //   })
        // }
        if (this.config.android_image) {
          let path = this.config.android_image
          if (!this.isAbsolutePath(path)) {
            path = `${DOWNLOAD_URL}static/image/${path}`
          }
          arr.push({
            name: '小程序',
            icon: 'iconAndroid',
            remark: '',
            img: path,
            link: ''
          })
        }
        return this.$i18n.locale === 'zh' ? arr : arr
      },
      banners() {
        if (this.$i18n.locale === 'zh') {
          return this.config.banners
        } else {
          return this.config.banners_en
        }
        // if (this.$i18n.locale === 'zh') {
        //   return [
        //     require(`../../assets/banners/${this.$i18n.locale}/banner1.jpg`),
        //     require(`../../assets/banners/${this.$i18n.locale}/banner2.jpg`),
        //     require(`../../assets/banners/${this.$i18n.locale}/banner3.jpg`),
        //     require(`../../assets/banners/${this.$i18n.locale}/banner4.jpg`),
        //     require(`../../assets/banners/${this.$i18n.locale}/banner5.jpg`)
        //   ]
        // } else {
        //   return [
        //     require(`../../assets/banners/${this.$i18n.locale}/banner1.jpg`),
        //     require(`../../assets/banners/${this.$i18n.locale}/banner2.jpg`),
        //     // require(`../../assets/banners/${this.$i18n.locale}/banner3.jpg`),
        //     // require(`../../assets/banners/${this.$i18n.locale}/banner4.jpg`),
        //     require(`../../assets/banners/${this.$i18n.locale}/banner5.jpg`)
        //   ]
        // }
      }
    }
  }

</script>

<style lang="scss" scoped>
  $bgColor: #F9EEEE;

  .icon {
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
  }

  $view-width: 1250px;

  .wrapper {
    min-width: $view-width;
    position: relative;
    //height: 100vh;
    //background-color: $bgColor !important;
    display: flex;
    align-items: center;
    flex-direction: column;
    //justify-content: center;
    min-height: 100vh;
    // background: url('../../assets/images/login.jpg') no-repeat center;
    background-size: cover;
    position: relative;
    z-index: 1;
    overflow: hidden;
    //background-image: linear-gradient(90deg, #3D75D6 0%, #6EC4DF 50%, #86E6C7 100%)
  }

  .wrapper.zh {
    .w-login {
      border: 1px solid rgba(61, 255, 244, .3);
      border-radius: 4px;
    }

    .w-login ::v-deep .w-login__btn button {
      background-color: #1C7CDD;
      border-radius: 4px;

      &:hover {
        opacity: .9;
      }
    }

    .w-login ::v-deep {
      .w-login__options_flex a {
        color: rgb(118, 173, 228);

        &:hover {
          color: rgb(159, 196, 233)
        }
      }

      .demo a {
        &:hover {
          color: rgb(138, 188, 238);
        }
      }

      .w-login__input_icon {
        color: #3DFFF4;
      }

      .w-login__input {
        background-color: transparent;
        border: 1px solid rgba(61, 255, 244, .3);

        input {
          color: #FFF !important;

          &::placeholder {
            color: #CCC;
          }
        }

        .w-login__suffix:not(:disabled) {
          color: rgb(118, 173, 228);
        }
      }

      .w-login__method {
        span.action {
          color: rgb(118, 173, 228);

          &:hover {
            color: rgb(159, 196, 233)
          }
        }
      }
    }
  }

  .gradual-top {
    position: absolute;
    top: 0;
    left: 0;
    //background-color: $bgColor !important;
    width: 100%;
    height: 60px;
    z-index: 2;
  }

  .gradual-main {
    top: 0;
    left: 0;
    width: 100%;
  }

  .middle {
    padding-top: 30px;
    background-color: #FAFAFA;

    .title {
      text-align: center;
    }

    .title_en {
      text-align: center;
    }
  }

  .w-footer {
    //background-color: $bgColor !important;
    width: 100%;
  }

  /* Demonstrate a "mostly customized" scrollbar
  * (won't be visible otherwise if width/height is specified) */

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    overflow: auto
  }

  ::-webkit-scrollbar-thumb {
    background-color: #e6e6e6;
    min-height: 25px;
    min-width: 25px;
    border: 1px solid #e0e0e0
  }

  ::-webkit-scrollbar-track {
    background-color: #f7f7f7;
    border: 1px solid #efefef
  }

  img.gswj {
    height: 18px;
    margin-right: 4px;
    vertical-align: middle;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: all .5s ease-out;
  }

  .fade-enter,
  .fade-leave-to

  /* .fade-leave-active below version 2.1.8 */
    {
    opacity: 0;
  }

  .animate {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: -2;

    .ele {
      position: absolute;
      z-index: 1;

      img {
        height: 100%;
      }
    }

    .circle {
      width: 60px;
      height: 60px;
      position: absolute;
      left: 50%;
      margin-left: -30px;
      bottom: -25px;
      z-index: -1;
      transform: scale(1.5, 0.5);

      .img {
        width: 100%;
        height: 100%;
        background: url(../../assets/images/circle.png) no-repeat center;
        background-size: cover;
        animation: circle-opacity 1.5s linear infinite alternate;
      }
    }

    .an1 {
      left: 28%;
      bottom: 40%;
    }

    .an2 {
      left: 29%;
      bottom: 16%;
    }

    .an3 {
      left: 46%;
      bottom: 55%;
    }

    .an4 {
      left: 49%;
      bottom: 42%;
    }

    .an5 {
      right: 18%;
      bottom: 14%;
    }

  }

  @keyframes circle-opacity {
    0% {
      transform: scale(1.2);
      opacity: 1;
    }

    25% {
      transform: scale(0.75);
      opacity: 0.75;

    }

    50% {
      transform: scale(0.5);
      opacity: 0.5;
    }

    75% {
      transform: scale(0.25);
      opacity: 0.25;
    }

    100% {
      transform: scale(0);
      opacity: 0.1;
    }
  }
  
  .loginBg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    img {
      display: block;
      width: 100%;
    }
  }

  .video {
    width: 100%;
    height: 100%;
    // background: url('../../assets/images/login.jpg') no-repeat center;
    // background-size: cover;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;

    video {
      width: 100%;
      height: 100%;
      object-fit: fill;
      /*这里是关键*/
    }

    .msk {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      top: 0;
      z-index: 1;
      background-color: #222229;
      opacity: 0.3;
    }

    .slogan {
      position: absolute;
      left: 14%;
      top: 30%;
      z-index: 2;
      text-align: left;
      color: #fff;

      h2 {
        font-size: 56px;
        font-weight: 600;
        line-height: 110px;
      }

      p {
        font-size: 24px;
        line-height: 32px;
      }
    }
  }

</style>
