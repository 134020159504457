<template>
  <transition name="fade">
    <div class="w-login">
      <span class="w-login__title" :class="$i18n.locale">{{title}}</span>
      <div class="w-login__method clearfix" :class="`${$i18n.locale}`">
        <template v-if="smsLogin">
          <span
            class="action"
            style="float:right"
            v-if="!form.ismobile"
            @click="form.ismobile=!form.ismobile"
          >{{$t('login.loginBySMS')}}</span>
          <span
            class="action"
            style="float:right"
            v-else
            @click="form.ismobile=!form.ismobile"
          >{{$t('login.loginByPassword')}}</span>
        </template>
      </div>
      <template v-if="!form.ismobile">
        <div class="w-login__input first-child">
          <div class="w-login__input_icon">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-yonghu" />
            </svg>
          </div>
          <input
            type="input"
            maxlength="50"
            v-model.trim="form.username"
            :placeholder="$t('login.userNamePlaceHolder')"
          />
        </div>
        <div class="w-login__input">
          
          <div class="w-login__input_icon">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-suoding" />
            </svg>
          </div>
          <input
            type="password"
            maxlength="50"
            v-model="form.password"
            @keypress="onKeypress($event)"
            :placeholder="$t('login.passWordPlaceHolder')"
            @keyup.enter="click"
          />
        </div>
      </template>
      <template v-else>
        <div class="w-login__input first-child">
          <div class="w-login__input_icon">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-yonghu" />
            </svg>
          </div>
          <input
            type="input"
            maxlength="11"
            v-model.trim="form.mobile"
            :placeholder="$t('login.pleaseInputMobile')"
          />
        </div>
        <div class="w-login__input">
          <div class="w-login__input_icon">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-suoding" />
            </svg>
          </div>
          <input
            type="input"
            maxlength="6"
            v-model.trim="form.code"
            :placeholder="$t('login.pleaseInputCode')"
            @keyup.enter="click"
          />
          <el-button
            class="w-login__suffix"
            type="text"
            :disabled="!!smsTime || disabledBtn"
            @click="onSendCode"
          >{{ `${smsTime > 0 ? $t('register.tryAgain')+'('+smsTime+')' : $t('register.sendButton')}`}}</el-button>
        </div>
      </template>

      <div class="w-login__remember" style="padding-top:5px;">
        <svg class="icon remember" v-if="true" aria-hidden="true" @click="remembered">
          <use :xlink:href="form.remember ? '#icon-checkbox' : '#icon-checkboxblank'" />
        </svg>
        <span
          class="remember"
          v-if="true"
          @click="remembered"
          :title="$t('login.autoLoginTitle')"
          v-t="'login.autoLogin'"
        ></span>
        <span class="demo">
          <a v-if="demoVisible && !error" :href="demoLink">{{$t('login.anonymous')}}>></a>
          <transition name="scale">
            <div class="w-login__error" v-if="error">{{error}}</div>
          </transition>
        </span>
      </div>
      <div class="w-login__remember" v-if="aVisible">
        <svg class="icon remember" aria-hidden="true" @click="agreed">
          <use :xlink:href="form.agreed ? '#icon-checkbox' : '#icon-checkboxblank'" />
        </svg>
        <span class="remember"  @click="agreed" style="flex-grow:1;width: 95%;">
          {{$t('login.ProtocolTips')}}
          <router-link target="_blank" :to="{ path: '/usageProtocol'}">《{{$t('login.ProtocolName')}}》</router-link>
          <router-link target="_blank" :to="{ path: '/securityProtocol'}">《{{$t('login.ProtocolName2')}}》</router-link>
        </span>
      </div>
      <div class="w-login__btn">
        <button type="button" @click="click" :class="loading ? 'loading' : ''">
          <svg class="icon icon-loading" aria-hidden="true" v-if="loading">
            <use xlink:href="#icon-loading" />
          </svg>
          {{$t('login.submitButton')}}
        </button>
      </div>
      <!-- <div class="w-login__options">
        <div class="w-login__options_flex" v-if="forgetVisible">
          <span>{{$t('login.forgetPassword')}}</span>
          <router-link tag="a" to="/forget">{{$t('login.findPassword')}}</router-link>
        </div>
        <w-split gap="8px" align="30px" color="#FFF"></w-split>
        <div class="w-login__options_flex" v-if="registerVisible">
          <span>{{$t('login.register')}}</span>
          <router-link tag="a" to="/register">{{$t('login.registerAccount')}}</router-link>
        </div>
      </div> -->
    </div>
  </transition>
</template>

<script>
import WSplit from "./w-split";
export default {
  components: {
    WSplit,
  },
  props: {
    title: {
      type: String,
      default: "Login",
    },
    // form: {
    //   type: Object,
    //   default: function () {
    //     return {
    //       username: '',
    //       password: '',
    //       remember: false,
    //       ismobile: false,
    //       mobile: '',
    //       code: ''
    //     }
    //   }
    // },
    smsLogin: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    demoVisible: {
      type: Boolean,
      default: true,
    },
    demoUrl: {
      type: String,
      default: "",
    },
    errorDelay: {
      type: Number,
      default: 5000,
    },
    forgetVisible: {
      type: Boolean,
      default: false,
    },
    registerVisible: {
      type: Boolean,
      default: false,
    },
    aVisible: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    error() {
      if (this.timer) {
        clearTimeout(this.timer);
      }

      this.timer = setTimeout(() => {
        this.error = "";
      }, this.errorDelay);
    },
  },
  data: function () {
    return {
      error: "",
      smsTime: 0,
      form: {
        username: "",
        password: "",
        remember: false,
        ismobile: false,
        mobile: "",
        code: "",
        agreed: false,
      },
      disabledBtn: false,
      language: localStorage.getItem("__language_key__")
    };
  },
  created() {
    if (
      localStorage.getItem("__visited_view_info__") &&
      JSON.parse(localStorage.getItem("__visited_view_info__")).userId
    ) {
      this.form.agreed = true;
    } else {
      this.form.agreed = false;
    }
  },
  methods: {
    click: function () {
      if(!this.aVisible){
        this.form.agreed = true;
      }
      this.$emit("login", this.form);
    },
    focus: function (e) {
      if (e.target.type == "text") {
        e.target.type = "password";
      }
    },
    onKeypress:function(event){
      if (!window.navigator.userAgent.indexOf("MSIE") > -1) {  // 非IE
        var e = event || window.event;
        var keyvalue = e.keyCode || e.which;
        if(keyvalue >= 65 && keyvalue <= 90){
          console.log('caps lock')
        }else if(keyvalue >= 97 && keyvalue <= 122){
          console.log('no')
        }else{
          console.log('nothing')
        }
      }
    },
    remembered: function () {
      this.form.remember = !this.form.remember;
    },
    agreed: function () {
      this.form.agreed = !this.form.agreed;
    },
    startTimer() {
      if (this.smsTime > 0) {
        return;
      }

      this.smsTime = 60;
      const decorate = () => {
        if (this.smsTime <= 0) {
          return;
        }
        this.smsTime--;
        setTimeout(decorate, 1000);
      };

      decorate();
    },
    onSendCode() {
      if (!this.form.mobile) {
        return;
      }
      this.disabledBtn = true;
      setTimeout(() => {
        this.disabledBtn = false;
      }, 1000);
      var reg = new RegExp(/^\d{6,13}$/);
      if (!reg.test(this.form.mobile)) {
        this.error = this.$t("login.errorMobile");
        return;
      }
      this.$emit("sendcode", this.form.mobile, () => {
        this.startTimer();
      });
    },
  },
  computed: {
    demoLink: function () {
      return (
        (this.demoUrl ? this.demoUrl : "http://demo.365qczx.com") +
        "?action=demo&refer=" +
        encodeURIComponent(window.location.origin)
      );
      // return (this.demoUrl ? this.demoUrl : 'http://demo.365qczx.com/') + 'Login_Demo.aspx?OutUrl=' + window.location.href;
    },
  },
};
</script>

<style lang="scss" scoped>
.w-login {
  padding: 20px;
  width: 300px;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 100px;
  z-index: 1;
  color: #fff;
  border-radius: 8px;
  height: 330px;
  box-sizing: content-box;

  &__tip {
    font-size: 14px;
    text-align: center;
    color: #eee;
    padding: 12px;
    border: 1px solid rgba(61, 255, 244, 0.3);
  }

  &__title {
    font-size: 24px;
    display: block;
    color: #fff;
    // margin-bottom: 30px;
    margin-bottom: 15px;
    margin-top: 10px;
    text-align: center;
    //letter-spacing: 0.5em;
    &.zh {
      letter-spacing: 0.5em;
    }
  }

  &__method {
    // position: absolute;
    font-size: 12px;
    user-select: none;
    height: 25px;
    line-height: 25px;
    visibility: hidden;
    span {
      cursor: pointer;
    }
    &.zh {
      visibility: visible;
    }
  }

  &__input {
    height: 45px;
    margin-bottom: 20px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 5px;
    border-radius: 2px;
    position: relative;

    &_icon {
      color: #606266;
      height: 45px;
      width: 45px;
      display: flex;
      font-size: 20px;
      align-items: center;
      justify-content: center;
      flex: 0 0 auto;
    }

    input {
      // flex-grow: 1;
      height: 45px;
      -webkit-appearance: none;
      border: none;
      background-color: transparent;
      color: #303133;
      font-size: 14px;
      outline: none;
      width: 0;
      flex: 1 1 auto;

      &:-webkit-autofill {
        transition-delay: 99999s !important;
        transition: color 99999s ease-out, background-color 99999s ease-out !important;
        -webkit-transition-delay: 99999s !important;
        -webkit-transition: color 99999s ease-out,
          background-color 99999s ease-out !important;
      }

      &::placeholder {
        font-size: 14px;
      }
    }
  }

  // &__input.first-child {
  //   margin-top: 45px;
  // }

  &__remember {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    transform: translateY(-15px);

    .icon {
      font-size: 16px;
    }

    .remember {
      cursor: pointer;
      a{
          color: #488bff;
          text-decoration: none;
      }
    }

    .demo {
      display: block;
      flex-grow: 1;
      text-align: right;

      a {
        color: #fff;
        text-decoration: none;

        &:hover {
          color: #f56c6c;
        }
      }
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    button {
      flex-grow: 1; // background-color: #409EFF;
      background-color: #e66464;
      height: 40px;
      text-align: center;
      line-height: 40px;
      border: 0;
      padding: 0;
      margin: 0;
      height: 100%;
      color: #fff;
      font-size: 14px;
      cursor: pointer;
      border-radius: 8px;
      outline: none;
      //letter-spacing: 0.5em;

      &:hover {
        // background-color: #66b1ff;
        background-color: #f56c6c;
      }

      &.loading {
        cursor: no-drop; // background-color: #66b1ff;
        background-color: #f56c6c;
      }
    }
  }

  &__error {
    font-size: 12px;
    color: #ffffff;
    text-align: right;
  }

  &__options {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 10px;

    &_flex {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 12px;

      a {
        // color: #53a8ff;
        color: #f56c6c;
        text-decoration: none;

        &:hover {
          color: #fa9191;
          cursor: pointer;
        }
      }

      span {
        margin-bottom: 10px;
      }
    }
  }
}

.icon-loading {
  animation: loading 1s linear infinite;
}

@keyframes loading {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loading {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@-moz-keyframes loading {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@-ms-keyframes loading {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
