<template>
  <div class="w-flex">
    <div class="w-flex__item" v-for="(item,index) in flexItems" :key="index">
      <div class="w-flex__item__top">
        <div class="w-flex__item__top__img" :class="item.css">
          <svg class="icon" aria-hidden="true">
            <use :xlink:href="'#' + item.img"></use>
          </svg>
        </div>
        <div class="w-flex__item__top__title">{{item.title}}</div>
      </div>
      <w-split gap="15px" align="15px" vertical compute></w-split>
      <div class="w-flex__item__bottom">
        <p class="w-flex__item__bottom__comment" v-for="(text,index) in item.comments" :key="index">{{text}}</p>
      </div>
    </div>
  </div>
</template>

<script>
  import WSplit from './w-split'
  export default {
    components: {
      WSplit
    },
    props: {
      flexItems: {
        type: Array,
        default: function () {
          return []
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .w-flex {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    padding-top: 30px;
    padding-bottom: 30px;
    margin: 0 auto;

    &__item:not(:first-child) {
      margin-left: 15px;
    }

    &__item:hover {
      box-shadow: 0 0 15px rgba(0, 0, 0, .15);
      transform: translateY(-10px);
      border: 1px solid #409EFF;
    }

    &__item {
      transition: all ease 0.3s;
      width: 280px;
      height: 330px;
      border: 1px solid #DCDFE6;
      display: flex;
      flex-direction: column;
      background-color: #ffffff;

      +& {}

      &__top {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 150px;

        &__img {
          height: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #303133;

          &.sim {
            color: #67C23A;
          }

          &.report {
            color: #E6A23C;
            .icon {
              font-size: 55px;
            }
          }

          &.setting {
            color: #F56C6C;
          }

          &.position {
            color: #409EFF;
          }

          .icon {
            font-size: 50px;
          }
        }

        &__title {
          padding-top: 10px;
        }
      }

      &__bottom {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        &__comment {
          margin: 5px 0;
          color: #606266;
        }
      }
    }
  }
</style>
