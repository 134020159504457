<template>
  <footer class="w-footer" :style="fStyle">
    <slot></slot>
    <div class="mask"></div>
  </footer>
</template>

<script>
  export default {
    props: {
      padding: {
        type: String,
        default: '0px'
      }
    },
    computed: {
      fStyle: function () {
        return {
          paddingTop: this.padding,
          paddingBottom: this.padding
        }
      }
    }
  }

</script>

<style lang="scss" scoped>
  .w-footer {
    // background-color: #303133;
    position: absolute;
    bottom: 0;
    z-index: 1;

    &-line {
      // width: 1200px;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      text-align: center;
      line-height: 24px;
      height: 24px;
      font-size: 12px;
      color: #e9e9e9;

      span {
        font-size: 12px;
      }

      a {
        color: #909399;
        font-size: 12px;
        text-decoration: none;
      }
    }

    .mask {
      width: 100%;
      height: 100%;
      background-color: #000;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      opacity: 0.3;
    }
  }

</style>
