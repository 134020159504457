<template>
  <div class="w-header" :class="{light: light}">
    <div class="w-content">
      <div class="w-content__item w-logo">
        <img :src="logo" />
        <span class="w-logo-text" :class="$i18n.locale">{{title}}</span>
      </div>
      <div class="w-content__item w-tip">
        <!-- {{tip}} -->
      </div>
      <div class="w-content__item w-tool">
        <w-tool-item v-for="item in tools" :icon="item.icon" :name="item.name" :key="item.name" :remark="item.remark"
          :img="item.img" :link="item.link"></w-tool-item>
        <el-dropdown trigger="click" @command="language" v-if="languageVisible">
          <span class="el-dropdown-link">
            <template v-if="$i18n.locale==='zh'">
              <i-icon name="icon-zhongguo" class="language-icon"></i-icon><span class="language-text">中文</span>
            </template>
            <template v-else-if="$i18n.locale==='fr'">
              <i-icon name="icon-faguo" class="language-icon"></i-icon><span class="language-text">‪Français</span>
            </template>
            <template v-else>
              <i-icon name="icon-meiguo" class="language-icon"></i-icon><span class="language-text">English</span>
            </template>
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="zh">
              <el-row align="middle" type="flex">
                <i-icon name="icon-zhongguo" class="language-icon"></i-icon>中文
              </el-row>
            </el-dropdown-item>
            <el-dropdown-item command="en">
              <el-row align="middle" type="flex">
                <i-icon name="icon-meiguo" class="language-icon"></i-icon>English
              </el-row>
            </el-dropdown-item>
            <el-dropdown-item command="fr">
              <el-row align="middle" type="flex">
                <i-icon name="icon-faguo" class="language-icon"></i-icon>‪Français
              </el-row>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
  import WToolItem from './w-tool-item'
  import {
    setLanguage
  } from '@/common/cache'
  import {
    setCommonParam
  } from '@/api/config'
  export default {
    components: {
      WToolItem
    },
    props: {
      logo: {
        type: String,
        default: ''
      },
      title: {
        type: String,
        default: ''
      },
      tip: {
        type: String,
        default: ''
      },
      tools: {
        type: Array,
        default: () => ([])
      },
      light: {
        type: Boolean,
        default: false
      },
      languageVisible: {
        type: Boolean,
        default: true
      }
    },
    created() {},
    methods: {
      language(key) {
        this.$i18n.locale = key
        setLanguage(key)
        setCommonParam({
          language: key
        })
      }
    }
  }

</script>

<style lang="scss" scoped>
  $view-width: 96%;

  .w-header {
    height: 42px;
    background-color: rgba(6, 16, 36, .2);
    font-size: 15px;
    color: #fff;
    z-index: 2;

    .w-logo {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;

      img {
        height: 30px;
      }

      &-text {
        color: #eaf2ff;
        margin-left: 10px;
        font-size: 24px;
        white-space: nowrap;
      }
    }

    .w-content {
      max-width: $view-width;
      margin: 0 auto;
      height: inherit;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .w-content__item {
        +.w-content__item {
          margin-left: 12px;
        }
      }

      .w-tip {
        flex-grow: 1;
        text-align: right;
        font-size: 12px;
        transform: translateY(15px);
        padding-right: 5px;
      }

      .w-tool {
        margin-left: 0 !important;
        width: 340px;
        height: inherit;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .el-dropdown-link {
          color: #FFF;
          cursor: pointer;

          .icon {
            color: #FFF;
            vertical-align: middle;
          }
        }

        .el-dropdown {
          margin-right: 12px;
        }
      }

      .w-title {
        font-size: 20px;
      }
    }

    &.light {
      .w-logo {
        &-text {
          color: #eaf2ff;
        }
      }
    }
  }

  .language-icon {
    font-size: 18px;
    margin-right: 6px;
  }

</style>
