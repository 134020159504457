<template>
  <div class="w-footer-line">
    <slot></slot>
  </div>
</template>

<script>
  export default {

  }

</script>

<style lang="scss" scoped>
  .w-footer-line {
    // width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 12px;

    span {
      font-size: 12px;
    }

    a {
      color: #e9e9e9;
      font-size: 12px;
      text-decoration: none;
    }
  }

</style>
